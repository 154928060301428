import { useRouteLoaderData } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import isEqual from 'lodash.isequal'

import { smartwayApi } from 'services/api'
import AccommodationStep from './AccommodationStep'
import { AccommodationStepSkeleton } from '../components/accommodations/AccommodationStepSkeleton'

const AccommodationStepFetchWrapper = () => {
  const { profile, offsite } = useRouteLoaderData('offsite-detail')
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [offsiteVenue, setOffsiteVenue] = useState({})
  const [linkedVenues, setLinkedVenues] = useState([])
  const [participants, setParticipants] = useState([])
  const [rooms, setRooms] = useState([])
  const [roomsAccommodations, setRoomsAccommodations] = useState([])

  const fetcher = useCallback(
    async ({ endpoint, ...params }) => {
      try {
        const response = await dispatch(
          smartwayApi.endpoints[endpoint].initiate({ ...params }, { forceRefetch: true })
        )
        if (response && response.data) {
          return response.data
        }
        return null
      } catch (error) {
        console.warn(error)
        return null
      }
    },
    [dispatch]
  )

  // GET
  const fetchOffsiteVenue = useCallback(async () => {
    const response = await fetcher({
      endpoint: 'fetchEntity',
      entity: offsite.all_in_venue ? 'allInVenue' : 'town',
      id: offsite.all_in_venue || offsite.town
    })
    if (response) {
      setOffsiteVenue(response)
    }
  }, [fetcher, offsite.all_in_venue, offsite.town])

  const fetchLinkedVenues = useCallback(async () => {
    const response = await Promise.all([
      ...(offsite.linked_towns && offsite.linked_towns.length
        ? offsite.linked_towns.map((t) => {
            return dispatch(
              smartwayApi.endpoints.fetchEntity.initiate({
                entity: 'town',
                id: t
              })
            )
          })
        : []),
      ...(offsite.linked_all_in_venues && offsite.linked_all_in_venues.length
        ? offsite.linked_all_in_venues.map((a) => {
            return dispatch(
              smartwayApi.endpoints.fetchEntity.initiate({
                entity: 'allInVenue',
                id: a
              })
            )
          })
        : [])
    ])

    if (response && !!response.length && response.some((r) => r.status === 'fulfilled')) {
      const results = response
        .filter((r) => r.status === 'fulfilled')
        .map((r) => ({...r.data, type: r.originalArgs.entity }))
        .flat()
      setLinkedVenues(results)
    }
  }, [offsite.linked_towns, offsite.linked_all_in_venues])

  const fetchParticipants = useCallback(async () => {
    const response = await fetcher({
      endpoint: 'fetchAllEntities',
      entity: 'participants',
      offsiteId: offsite.id,
      params: { page_size: 100 }
    })
    if (response) {
      setParticipants(response)
    }
  }, [fetcher, offsite.id])

  const fetchRooms = useCallback(async () => {
    const response = await fetcher({
      endpoint: 'fetchAllEntities',
      entity: 'rooms',
      offsiteId: offsite.id,
      params: { page_size: 100 }
    })
    if (response) {
      setRooms(
        [...response].sort((a, b) => {
          return a.id > b.id ? 1 : -1
        }).map((r) => {
          return {
            ...r,
            venueType: r.room ? 'town' : 'allInVenue'
          }
        })
      )
    }
  }, [fetcher, offsite.id])

  const fetchRoomsAccommodation = useCallback(
    async (accommodation) => {
      const _roomsAccommodation = await dispatch(
        smartwayApi.endpoints.fetchEntity.initiate({
          entity: 'venueAccommodation',
          id: accommodation
        })
      )
      if (_roomsAccommodation && _roomsAccommodation.data) {
        setRoomsAccommodations([_roomsAccommodation.data])
      }
    },
    [dispatch]
  )


  const fetchRoomsAccommodations = useCallback(
    async (rooms) => {
      const accommodations = rooms.reduce((acc, curr) => {
        let entity = 'accommodation'
        if (!curr.room && curr.all_in_venue_room) {
          entity = 'venueAccommodation'
        }
        const { accommodation } = curr.detail
        if (accommodation && !acc.map(({ id }) => id).includes(accommodation)) {
          return [...acc, { id: accommodation, entity }]
        }
        return acc
      }, [])
      const _roomsAccommodations = await Promise.all(
        accommodations.map((accommodation) => {
          return dispatch(
            smartwayApi.endpoints.fetchEntity.initiate({
              entity: accommodation.entity,
              id: accommodation.id
            })
          )
        })
      )
      if (_roomsAccommodations && !!_roomsAccommodations.length) {
        setRoomsAccommodations(_roomsAccommodations.map((r) => r.data))
      }
    },
    [dispatch]
  )

  // POST/PATCH
  const randomFillAllRooms = async ({ rooms }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await fetcher({
          endpoint: 'createOffsiteEntity',
          offsiteId: offsite.id,
          entity: 'rooms',
          action: 'fill',
          rooms
        })
        await Promise.all([fetchRooms(), fetchParticipants()])
        resolve()
      } catch (error) {
        console.warn(error)
        reject(error)
      }
    })
  }
  const randomFillRoom = useCallback(
    async ({ id, participants }) => {
      return new Promise(async (resolve, reject) => {
        try {
          await fetcher({
            endpoint: 'updateOffsiteEntity',
            id,
            offsiteId: offsite.id,
            entity: 'rooms',
            participants
          })
          await Promise.all([fetchRooms(), fetchParticipants()])
          resolve()
        } catch (error) {
          console.warn(error)
          reject(error)
        }
      })
    },
    [fetchParticipants, fetchRooms, fetcher, offsite.id]
  )

  const saveRooms = useCallback(
    async (_rooms) => {
      setIsLoading(true)
      const rooms = _rooms.filter(r => r.venueType === 'town')
      const all_in_venues_rooms = _rooms.filter(r => r.venueType === 'allInVenue')
      try {
        await fetcher({
          endpoint: 'updateEntity',
          entity: 'offsite',
          id: offsite.id,
          ...(rooms && rooms.length ? { rooms } : {}),
          ...(all_in_venues_rooms && all_in_venues_rooms.length ? { all_in_venues_rooms } : {}),
          company: offsite.company
        })
        await fetchRooms()
        setIsLoading(false)
      } catch (error) {
        console.warn(error)
      }
    },
    [fetchRooms, fetcher, offsite.id]
  )

  const updateParticipant = useCallback(
    async ({ roomId, participantId, previousParticipant }) => {
      return new Promise(async (resolve, reject) => {
        try {
          await Promise.all([
            fetcher({
              endpoint: 'updateOffsiteEntity',
              entity: 'participants',
              id: participantId,
              room: roomId,
              offsiteId: offsite.id
            }),
            ...(previousParticipant
              ? [
                  fetcher({
                    endpoint: 'updateOffsiteEntity',
                    id: previousParticipant,
                    offsiteId: offsite.id,
                    entity: 'participants',
                    room: null
                  })
                ]
              : [])
          ])

          await Promise.all([fetchParticipants(), fetchRooms()])
          resolve()
        } catch (error) {
          console.warn(error)
          reject(error)
        }
      })
    },
    [fetchParticipants, fetchRooms, fetcher, offsite.id]
  )

  const updateRoom = useCallback(
    async ({ id, full, ...rest }) => {
      return new Promise(async (resolve, reject) => {
        try {
          await fetcher({
            endpoint: 'updateOffsiteEntity',
            entity: 'rooms',
            id,
            full,
            ...rest,
            offsiteId: offsite.id
          })
          await fetchRooms()
          resolve()
        } catch (error) {
          console.warn(error)
          reject(error)
        }
      })
    },
    [fetchRooms, fetcher, offsite.id]
  )

  const removeRoom = useCallback(
    async (offsiteRoom) => {
      try {
        await fetcher({
          endpoint: 'deleteOffsiteEntity',
          id: offsiteRoom.id,
          offsiteId: offsite.id,
          entity: 'rooms'
        })
        await fetchRooms()
      } catch (error) {
        console.warn(error)
      }
    },
    [fetchRooms, fetcher, offsite.id]
  )

  const removeParticipantsFromRoom = useCallback(
    async ({ participantsToRemove }) => {
      try {
        const promises = participantsToRemove.map((id) => {
          return fetcher({
            endpoint: 'updateOffsiteEntity',
            id,
            offsiteId: offsite.id,
            entity: 'participants',
            room: null
          })
        })
        await Promise.all(promises)
        await Promise.all([fetchParticipants(), fetchRooms()])
      } catch (error) {
        console.warn(error)
      }
    },
    [fetchParticipants, fetcher, offsite.id]
  )

  // EFFECTS
  useEffect(() => {
    if (rooms && !!rooms.length) {
      const allAccommodationsFetched = rooms.every(({ room, all_in_venue_room, detail }) => {
        const { accommodation } = detail
        const found = roomsAccommodations.find((acc) => acc?.id === accommodation)
        return found && ((!!room && !!found?.town) || (!!all_in_venue_room && !!found?.venue))
       })
      if (!allAccommodationsFetched) {
        fetchRoomsAccommodations(rooms)
        if (participants && !!participants.length) {
          setParticipants(participants)
        }
      }
    } else {
      const isOneAccommodation = (offsite.all_in_venue &&
      offsiteVenue.accommodations &&
      offsiteVenue.accommodations?.length === 1) && !linkedVenues.length

      if (isOneAccommodation) {
        fetchRoomsAccommodation(offsiteVenue.accommodations[0])
      }
    }
  }, [rooms, offsiteVenue, linkedVenues])

  useEffect(() => {
    setIsLoading(true)
    const fetchInitial = async () => {
      await Promise.all([
        fetchOffsiteVenue(),
        fetchLinkedVenues(),
        fetchParticipants(),
        fetchRooms()
      ])
      setIsLoading(false)
    }
    fetchInitial()
  }, [fetchOffsiteVenue, fetchParticipants, fetchRooms])

  return isLoading ? (
    <AccommodationStepSkeleton town={offsiteVenue.name} />
  ) : (
    <AccommodationStep
      title="Accommodations"
      offsite={offsite}
      profile={profile}
      participants={participants}
      rooms={rooms}
      offsiteVenueType={offsite.all_in_venue ? 'allInVenue' : 'town'}
      offsiteVenue={offsiteVenue}
      linkedVenues={linkedVenues}
      roomsAccommodations={roomsAccommodations}
      onRandomFillRoom={randomFillRoom}
      onRandomFillAllRooms={randomFillAllRooms}
      onSaveRooms={saveRooms}
      onUpdateParticipant={updateParticipant}
      onUpdateRoom={updateRoom}
      onRemoveRoom={removeRoom}
      onRemoveParticipantsFromRoom={removeParticipantsFromRoom}
    />
  )
}

export default AccommodationStepFetchWrapper
