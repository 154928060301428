import * as yup from 'yup'
import { smartwayApi } from 'services/api'
import { humanFileSize } from './functions'

const customValidations = {
  vat: {
    type: 'string',
    handler: function (message) {
      return this.test('vat', message, function (value) {
        const { path, createError } = this
        if (!value) return true // Skip validation if no vat is entered
        const vatRegex =
          /^(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|GB|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)(\d{9,11})$/
        return (value && vatRegex.test(value)) || createError({ path, message })
      })
    }
  },
  password: {
    type: 'string',
    handler: function (message) {
      return this.test('password', message, function (value) {
        const { path, createError } = this
        /**
         * Password must include:
         * - at least one lowercase letter
         * - at least one uppercase letter
         * - at least one digit
         * - at least one special character
         * - at least 8 characters
         * - at least one of the following special characters: @, $, !, %, *, ?, &, ., |, \, /, (, ), ,, ", ', £, €, §, ^, *, #, -, _, é, à, ò, à, è
         */
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.|\\£€§^*#\-_éàòè])[A-Za-z\d@$!%*?&.|\\\/£€§^*;#\-_,()"'éàòè]{8,}$/
        
        return (value && passwordRegex.test(value)) || createError({ path, message })
      })
    }
  },
  fileFormat: {
    type: 'string',
    handler: function (message, acceptedFormats) {
      return this.test('fileFormat', message, function (value) {
        const { path, createError, originalValue } = this
        if (!originalValue || !originalValue.type) return true
        return (
          acceptedFormats.includes(originalValue.type) ||
          createError({ path, message })
        )
      })
    }
  },
  fileSize: {
    type: 'mixed',
    handler: function (message, maxSize) {
      return this.test('fileSize', message, function (value) {
        const { path, createError, originalValue } = this
        if (Array.isArray(originalValue)) {
          const filesTooBig = originalValue.filter(f => f.size >= maxSize)
          if (filesTooBig.length) {
            const multipleFiles = filesTooBig.length > 1
            const message = multipleFiles ? `Some files are too big (max size: ${humanFileSize(maxSize)})` : `File size too big (max size: ${humanFileSize(maxSize)}): ${filesTooBig[0].name} - ${humanFileSize(filesTooBig[0].size)}`
            return createError({ path, message })
          } 
        }
        if (!originalValue || !originalValue.size) return true
        return originalValue?.size <= maxSize || createError({ path, message })
      })
    }
  },
  latLng: {
    type: 'number',
    handler: function (message, value) {
      return this.test('latLng', message, function () {
        const { path, createError } = this
        if (!value) {
          return true // allow empty value
        }
        const decimalRegex = /^\d*(\.\d{1,2})?$/
        return (
          decimalRegex.test(value.toString()) || createError({ path, message })
        )
      })
    }
  },
  price: {
    type: 'string',
    handler: function (message, value) {
      return this.test('price', message, function () {
        const { path, createError } = this
        if (!value) {
          return true // allow empty value
        }
        const decimalRegex = /^\d*(\.\d{1,2})?$/
        return (
          decimalRegex.test(value.toString()) || createError({ path, message })
        )
      })
    }
  },
  isEqualTo: {
    type: 'string',
    handler: function (message, fieldName) {
      return this.test('isEqual', message, function (value, instance) {
        const { path, createError, parent } = instance
        const value2 = parent[fieldName]
        if (!value && !value2) {
          return true // allow empty value
        }
        return value === value2 || createError({ path, message })
      })
    }
  },
  dateDependsOn: {
    type: 'string',
    handler: function (message, condition, fieldName) {
      return this.test('dateDependsOn', message, function (_value, instance) {
        const value = new Date(_value).getTime()
        const { path, createError, parent } = instance
        const value2 = new Date(parent[fieldName]).getTime()
        if (!value && !value2) {
          return true // allow empty value
        }
        switch (condition) {
          case 'greaterThan':
            return value > value2 || createError({ path, message })
          case 'lessThan':
            return value < value2 || createError({ path, message })
          default:
            return true
        }
      })
    }
  },
  slug: {
    type: 'string',
    handler: function (entity, message, originalValue, dispatch) {
      return this.test('slug', message, async function (value, instance) {
        const { path, createError } = instance
        if (!value) {
          return true // allow empty value
        }
        const { data: { exists }} = await dispatch(smartwayApi.endpoints.createEntity.initiate({ entity: 'slugChecker', body: { slug: value, entity } }))
        return exists && value !== originalValue ? createError({ path, message }) : true
      })
    }
  },
  acceptedValues: {
    type: 'array',
    handler: function (message, values) {
      return this.test('acceptedValues', message, function (value) {
        const { path, createError } = this
        if (!value) return true
        return (
          !value.some(v => values.includes(v)) ||
          createError({
            path,
            message: { message: `${message}: ${value.filter((v) => values.includes(v)).join(', ')}`, context: value.filter((v) => values.includes(v)) } 
          })
        )
      })
    }
  }
}

Object.keys(customValidations).forEach((key) => {
  const custom = customValidations[key]
  yup.addMethod(yup[custom.type], key, custom.handler)
})

const getAddressFields = (field) => {
  return [
    { ...field },
    {
      type: 'number',
      name: field.latitudeProp || 'latitude',
      validationType: 'number',
      validations: [
        {
          type: 'latLng',
          params: ['This field is not a valid latitude or longitude']
        }
      ]
    },
    {
      type: 'number',
      name: field.longitudeProp || 'longitude',
      validationType: 'number',
      validations: [
        {
          type: 'latLng',
          params: ['This field is not a valid latitude or longitude']
        }
      ]
    }
  ]
}

const createValidationSchema = (fieldsets) => {
  let validationSchema = {}
  fieldsets.forEach(({ fields: f }) => {
    const fieldsetValidationSchema = f.reduce((acc, curr) => {
      switch (curr.type) {
        case 'address':
          const addressFields = getAddressFields(curr)
          addressFields.forEach((field) => {
            acc = createFieldValidationSchema(acc, field)
          })
          break
        case 'checkboxgroup':
          if (!!curr.groups) {
            curr.groups.forEach((group) => {
              group.controls.forEach((control) => {
                acc = createFieldValidationSchema(acc, {
                  ...control,
                  validationType: 'boolean'
                })
              })
            })
          }
          break
        case 'datepickerrange':
          ['start', 'end'].forEach((prop) => {
            acc = createFieldValidationSchema(acc, {
              ...curr,
              name: curr[prop].name
            })
          })
          break
        default:
          acc = createFieldValidationSchema(acc, curr)
          break
      }
      return acc
    }, {})
    validationSchema = {
      ...validationSchema,
      ...fieldsetValidationSchema
    }
  })

  return yup.object().shape(validationSchema)
}

const createFieldValidationSchema = (schema, config) => {
  const { name, validationType, validations = [] } = config

  if (!yup[validationType]) {
    return schema
  }
  let validator = yup[validationType]()
  if (validations && !!validations.length) {
    validations.forEach(({ params = [], type }) => {
      if (!validator[type]) {
        return
      }
      if (
        type === 'when' &&
        typeof params[1] === 'object' &&
        params[1].is &&
        params[1].then
      ) {
        let thenValidator = yup[validationType]()
        params[1].then.forEach((t) => {
          thenValidator = validator[t.type](...t.params)
        })
        const valueToCheck = params[1].is.split('not:')
        validator = validator[type](params[0], {
          is: (value) => {
            return valueToCheck.length > 1
              ? value !== valueToCheck[1]
              : !!value && valueToCheck[0] === value
          },
          then: thenValidator
        })
      } else {
        validator = validator[type](...params)
      }
    })
  }

  schema[name] = validator
  return schema
}

const createInitialValues = (fieldsets, values = {}) => {
  let initialValues = {}
  fieldsets.forEach(({ fields: f }) => {
    f.forEach(({ name, type, value, ...rest }) => {
      let _value = value || values[name] || ''
      switch (type) {
        case 'radio':
        case 'checkbox':
        case 'switch':
          _value = !!_value
          initialValues[name] = _value
          break
        case 'select':
          const selectDefaultValue = rest.multiple ? [] : null
          _value = _value ? _value : selectDefaultValue
          initialValues[name] = _value
          break
        case 'checkboxgroup':
          initialValues[name] = _value || []
          break
        case 'number':
          _value = !!_value ? Number(_value) : 0
          initialValues[name] = _value
          break
        case 'address':
          const latitudeProp = rest.latitudeProp || 'latitude'
          const longitudeProp = rest.longitudeProp || 'longitude'
          initialValues[name] = _value
          initialValues[latitudeProp] = values[latitudeProp]
            ? Number(values[latitudeProp])
            : 0
          initialValues[longitudeProp] = values[longitudeProp]
            ? Number(values[longitudeProp])
            : 0
          break
        case 'file':
          initialValues[name] = rest.showPreview ? values[name] : null
          break
        case 'image': 
          const defaultReturnedKey = rest.returnedKey || 'formats'
          initialValues[name] = values && values[defaultReturnedKey] ? values[defaultReturnedKey].mobile : ''
          break
        case 'datepicker':
          initialValues[name] = _value || null
          break
        case 'datepickerrange':
          initialValues[name] = [
            values[rest.start.name] ? new Date(values[rest.start.name]) : null,
            values[rest.end.name] ? new Date(values[rest.end.name]) : null
          ]
          initialValues[rest.start.name] = values[rest.start.name] ? new Date(values[rest.start.name]) : null
          initialValues[rest.end.name] = values[rest.end.name] ? new Date(values[rest.end.name]) : null
          break
        default:
          initialValues[name] = _value
          break
      }
    })
  })
  return initialValues
}

const normalizeField = (field) => {
  const _field = {...field}
  const toRemove = ['validationType', 'validations', 'grid']
  toRemove.forEach((prop) => {
    if (_field[prop]) delete _field[prop]
  })
  return _field
}

export { createValidationSchema, createInitialValues, normalizeField }
