const venue = ({ groups, allInVenue, dispatch, onPublishChange }) => {
  const concierge = groups && groups.length ? groups.find((g) => g.name === 'Concierge') : {}
  const publishButton = allInVenue?.id
    ? allInVenue.published_at
      ? {
          label: 'Unpublish',
          color: 'warning',
          icon: 'visibility_off',
          tooltip: 'By unpublishing, you will not be able to see the public page anymore',
          onClick: () => onPublishChange(false, allInVenue)
        }
      : {
          label: 'Publish',
          color: 'success',
          tooltip: 'Be sure to have saved before publishing',
          icon: 'rocket_launch',
          onClick: () => onPublishChange(true, allInVenue)
        }
    : null
  return {
    id: 'venue-form',
    title: 'All-In Venue Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: 'General informations',
        type: 'accordion',
        fields: [
          {
            type: 'text',
            label: 'Name of the venue',
            name: 'name',
            required: true,
            validationType: 'string',
            helperText:
              'The name of the venue should be unique. Try to not write the name of a venue that already exists.',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            label: 'Slug',
            name: 'slug',
            slugify: true,
            required: !!allInVenue.id,
            hidden: !allInVenue.id,
            validationType: 'string',
            validations: allInVenue.id
              ? [
                  {
                    type: 'slug',
                    params: [
                      'all-in-venues',
                      'This slug is not available',
                      allInVenue.slug,
                      dispatch
                    ]
                  },
                  {
                    type: 'required',
                    params: ['This field is required']
                  }
                ]
              : null
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: null
          },
          {
            type: 'select',
            name: 'concierge',
            label: 'Concierge',
            labelProp: 'name',
            valueProp: 'profileId',
            optionsGetter: {
              endpoints: [
                {
                  entity: 'profilesByRole',
                  params: { roles: concierge?.id, transformResponse: true }
                }
              ]
            },
            grid: { xs: 6 },
            validationType: 'number',
            validations: [
              {
                type: 'nullable'
              }
            ]
          },
          {
            name: 'phone_number',
            type: 'text',
            label: 'Phone Number',
            grid: { xs: 6 },
            validationType: 'string',
            validations: null
          },
          {
            name: 'email',
            type: 'text',
            label: 'Email',
            required: true,
            grid: { xs: 6 },
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'address',
            name: 'address',
            label: 'Location',
            latitudeProp: 'address_latitude',
            longitudeProp: 'address_longitude',
            addressComponents: [
              {
                name: 'country',
                component: 'country'
              },
              {
                name: 'region',
                component: 'region'
              }
            ],
            required: true,
            helperText: 'This information is useful for Google Maps',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            placeholder: 'Write a description',
            validationType: 'string',
            required: true,
            maxLength: 2000,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'max',
                params: [2000, 'Description is too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'textarea',
            name: 'cancellation_terms',
            label: 'Cancellation terms',
            placeholder: 'Write something',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'max',
                params: [2000, 'Cancellation terms are too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'inputlist',
            name: 'nearest_cities',
            label: 'List of nearest cities',
            placeholder: 'Roma',
            validationType: 'string',
            validations: null
          },
          {
            type: 'textarea',
            name: 'transports_description',
            label: 'Transports Description',
            placeholder: 'Write a description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'max',
                params: [2000, 'Transports description is too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'inputlist',
            name: 'transports',
            label: 'List of transports and travel time',
            placeholder: '2h30 from Rome by train',
            validationType: 'string',
            validations: null
          },
          {
            type: 'video',
            label: 'Video',
            name: 'video',
            placeholder: 'Upload a video',
            validationType: 'string',
            collectionName: 'towns',
            innerField: {
              validationType: 'mixed',
              validations: [
                {
                  type: 'fileSize',
                  params: ['File too large', '104860000']
                },
                {
                  type: 'fileFormat',
                  params: ['Unsupported Format', ['video/mp4']]
                },
                {
                  type: 'nullable'
                }
              ],
              maxSize: 104860000,
              accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
            }
          }
        ]
      },
      {
        id: 'settings',
        title: 'Settings',
        type: 'accordion',
        fields: [
          {
            type: 'select',
            name: 'categories',
            label: 'Categories of accommodation *',
            multiple: true,
            optionsGetter: {
              endpoints: [
                {
                  entity: 'locationCategories',
                  id: 'all_in_venues'
                }
              ]
            },
            labelProp: 'name',
            valueProp: 'id',
            required: true,
            validationType: 'array',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              },
              {
                type: 'min',
                params: [1, 'You must select at least one category']
              }
            ],
            grid: { xs: 6, mr: 6 }
          },
          {
            type: 'text',
            label: 'Parking name',
            name: 'park_name',
            validationType: 'string',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'address',
            name: 'park_address',
            label: 'Parking',
            latitudeProp: 'park_latitude',
            longitudeProp: 'park_longitude',
            validationType: 'string'
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default venue
