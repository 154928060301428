import { useState, useEffect, useCallback } from 'react'

const BUNNY_COLLECTION_BASE_URL = 'https://video.bunnycdn.com'
// const BUNNY_LIBRARY_ID = '178997'
// const BUNNY_ACCESS_KEY = '43e0f5b6-f643-48b8-9dbfccebc770-0c62-44cb'


// const BUNNY_BASE_URL = 'https://vz-105ac75d-42c.b-cdn.net'
const BUNNY_COLLECTION_LIBRARY_ID = '186373'
const BUNNY_COLLECTION_ACCESS_KEY = 'bc124767-f854-471a-b667bf0c2e5a-a412-4e64'

export const constants = {
  BUNNY_COLLECTION_BASE_URL,
  BUNNY_COLLECTION_LIBRARY_ID
}

const useBunnyCollection = ({ collection }) => {
  const [existingCollection, setExistingCollection] = useState(null)
  const [availableVideos, setAvailableVideos] = useState(null)
  const [isLoadingVideos, setIsLoadingVideos] = useState(true)
  const [isError, setIsError] = useState(null)

  const getVideoCollection = useCallback(async ({ collectionName }) => {
    const options = {
      method: 'GET',
      headers: {
        AccessKey: BUNNY_COLLECTION_ACCESS_KEY,
        accept: 'application/json',
        'content-type': 'application/*+json'
      }
    }
    try {
      // Get collections filtered by collectionName
      const res = await fetch(
        `${BUNNY_COLLECTION_BASE_URL}/library/${BUNNY_COLLECTION_LIBRARY_ID}/collections?search=${collectionName}`,
        options
      )
      const response = await res.json()
      if (response && response.items && response.items.length) {
        const collection = response.items[0]
        setExistingCollection(collection)
        // If the collection already exists, search for videos
        if (collection.videoCount) {
          const videoRequest = await fetch(
            `${BUNNY_COLLECTION_BASE_URL}/library/${BUNNY_COLLECTION_LIBRARY_ID}/videos?collection=${collection.guid}`,
            options
          )
          const videoResponse = await videoRequest.json()
          if (videoResponse && videoResponse.items) {
            setAvailableVideos(videoResponse.items)
            setIsLoadingVideos(false)
          }
        } else {
          setIsLoadingVideos(false)
        }
      } else {
        // If no collection is found, create one
        // This use case is important for companies collections
        const createCollectionRequest = await fetch(
          `${BUNNY_COLLECTION_BASE_URL}/library/${BUNNY_COLLECTION_LIBRARY_ID}/collections`,
          {
            ...options,
            method: 'POST',
            body: JSON.stringify({ name: collectionName })
          }
        )
        const createCollectionResponse = await createCollectionRequest.json()
        if (createCollectionResponse && createCollectionResponse.guid) {
          setExistingCollection(createCollectionResponse)
          setIsLoadingVideos(false)
        } else {
          setIsError('Not able to create video collection, try later')
        }
      }
    } catch (error) {
      console.warn(error)
    }
  }, [])

  const getVideo = useCallback(async ({ videoId }) => {
    try {
      const response = await fetch(
        `${BUNNY_COLLECTION_BASE_URL}/library/${BUNNY_COLLECTION_LIBRARY_ID}/videos/${videoId}`,
        {
          headers: {
            AccessKey: BUNNY_COLLECTION_ACCESS_KEY
          }
        }
      )
      const video = await response.json()
      return video
    } catch (error) {
      console.warn(error)
      return null
    }
  }, [])

  const uploadVideo = useCallback(async ({ file }) => {
    const options = {
      method: 'POST',
      headers: {
        AccessKey: BUNNY_COLLECTION_ACCESS_KEY,
        accept: 'application/json',
        'content-type': 'application/*+json'
      },
      body: JSON.stringify({
        title: file.name,
        collectionId: existingCollection ? existingCollection.guid : ''
      })
    }
    try {
      const res = await fetch(
        `${BUNNY_COLLECTION_BASE_URL}/library/${BUNNY_COLLECTION_LIBRARY_ID}/videos`,
        options
      )
      const response = await res.json()
      if (response) {
        const { guid } = response

        const uploadOptions = {
          method: 'PUT',
          headers: {
            AccessKey: BUNNY_COLLECTION_ACCESS_KEY,
            accept: 'application/json',
            'Content-Type': 'application/octet-stream'
          },
          body: file
        }
        const uploadRes = await fetch(
          `${BUNNY_COLLECTION_BASE_URL}/library/${BUNNY_COLLECTION_LIBRARY_ID}/videos/${guid}`,
          uploadOptions
        )
        const uploadResponse = await uploadRes.json()
        if (uploadResponse) {
          return {
            status: 'success', guid 
          }
        }
      }
    } catch (error) {
      console.warn(error)
      return {
        status: 'error',
        error
      }
    }
  }, [existingCollection])

  useEffect(() => {
    if (collection) {
      getVideoCollection({ collectionName: collection })
    }
  }, [collection, getVideoCollection])

  return {
    collection: existingCollection,
    videos: availableVideos,
    isLoading: isLoadingVideos,
    isError,
    getVideo,
    uploadVideo
  }
}

export default useBunnyCollection
