const otherFields = [
  {
    type: 'text',
    name: 'title',
    label: 'Title',
    validationType: 'string',
    validations: null
  },
  {
    type: 'checkbox',
    name: 'featured',
    label: 'Featured',
    validationType: 'boolean',
    validations: null
  }
]

const schema = ({ editingImage, hideOtherFields = false, multiple = false }) => {
  return {
    name: 'edit-image-form',
    title: 'Image',
    fieldsets: [
      {
        id: 'edit-image',
        fields: [
          {
            type: 'file',
            name: 'image',
            label: 'Upload one or more images (max 5Mb)',
            multiple,
            validationType: 'mixed',
            maxSize: 5242880,
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '5242880']
              },
              {
                type: 'fileFormat',
                params: [
                  'Unsupported Format',
                  ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/svg+xml']
                ]
              },
              {
                type: 'nullable'
              }
            ],
            accept: 'image/png,image/jpeg,image/jpg,image/svg+xml'
          },
          ...(editingImage && !hideOtherFields ? otherFields : [])
        ]
      }
    ]
  }
}

export default schema
