export const capitalizeFirstLetter = (string) =>
  !!string && typeof string === 'string'
    ? string.replace(/^./, string[0].toUpperCase())
    : ''

export const titleCase = (str) => str.toLowerCase().replace(/(^|\s)\w/g, (match) => match.toUpperCase()).replaceAll('_', ' ')

export const slugify = (str) => {
  let slug = str
  slug = slug.toLowerCase(); // convert string to lowercase
  slug = slug.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
           .replace(/\s+/g, '-') // replace spaces with hyphens
           .replace(/-+/g, '-') // remove consecutive hyphens
           .trim()
  return slug
}

export const removeDuplicates = (list, property) => {
  const flag = {}
  const unique = []
  list.forEach((elt) => {
    if (!flag[elt[property]]) {
      flag[elt[property]] = true
      unique.push(elt)
    }
  })
  return unique
}

export const stripTags = (string) =>
  !!string && typeof string === 'string' ? string.replace(/(<([^>]+)>)/gi, '') : ''

export const excerptHtml = (htmlString, charactersNumber = 120, closeParagraph = true) => {
  if (htmlString.length < charactersNumber) return htmlString
  let endIndex = htmlString.indexOf('</p>', charactersNumber)
  if (!closeParagraph) {
    endIndex = htmlString.indexOf(' ', charactersNumber)
  }
  if (endIndex !== -1) {
    return `${htmlString.substring(0, endIndex)}...</p>`
  } 
  return `${htmlString.substring(0, charactersNumber)}...`
}

export const getLoadingButtonColors = (color, sx = {}) => {
  switch (color) {
    case 'primary':
      return {
        sx: {
          ...(sx || {}),
          color: 'white !important',
          backgroundColor: 'primary.main',
          '&:hover': { backgroundColor: 'primary.light' }
        }
      }
    case 'info':
      return {
        sx: {
          ...(sx || {}),
          color: 'white !important',
          backgroundColor: 'info.main',
          '&:hover': { backgroundColor: 'info.light' }
        }
      }
    case 'dark':
      return {
        sx: {
          ...(sx || {}),
          color: 'white !important',
          backgroundColor: 'dark.focus',
          '&:hover': { backgroundColor: 'dark.main' }
        }
      }
    default:
      return {
        sx: {
          ...(sx || {}),
          color: 'white !important',
          backgroundColor: 'primary.main',
          '&:hover': { backgroundColor: 'primary.light' }
        }
      }
  }
}

const stringToColor = (string) => {
  let hash = 0
  let i
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

export const stringAvatar = (name, sx = {}) => {
  return {
    sx: {
      ...sx,
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export const humanFileSize = (size) => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}