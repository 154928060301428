const getSchema = ({ editingMode, entity, venueType, venue, dispatch, handleNoResults, onPublishChange }) => {

  const publishButton =  entity?.id ? entity?.published_at ? {
    label: 'Unpublish',
    color: 'warning',
    tooltip: 'By unpublishing, you will not be able to see the public page anymore',
    icon: 'visibility_off',
    onClick: () => onPublishChange(false, entity)
  } : {
    label: 'Publish',
    color: 'success',
    tooltip: 'Be sure to have saved before publishing',
    icon: 'rocket_launch',
    onClick: () => onPublishChange(true, entity)
  } : null

  return {
    id: 'package-form',
    title: editingMode ? entity.title : `New package for ${venue.name}`,
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        fields: [
          {
            type: 'text',
            name: 'title',
            label: 'Title',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          }, 
          {
            type: 'text',
            name: 'slug',
            slugify: true,
            label: 'Slug',
            required: editingMode,
            hidden: !editingMode,
            validationType: 'string',
            validations: editingMode ? [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'slug',
                params: ['premade-packages', 'Slug is not available', entity.slug, dispatch]
              }
            ] : null
          },
          {
            type: 'text',
            label: 'Subtitle',
            name: 'subtitle',
            validationType: 'string',
            validations: null,
            grid: { xs: 12 },
          },
          {
            type: 'textarea',
            label: 'Excerpt',
            name: 'excerpt',
            validationType: 'string',
            validations: null,
            grid: { xs: 12 },
          },
          {
            type: 'textarea',
            label: 'Description',
            name: 'description',
            validationType: 'string',
            required: true,
            maxLength: 3000,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'max',
                params: [3000, 'Description is too long. Keep it under 3000 characters']
              }
            ],
            grid: { xs: 12 },
          },
          {
            type: 'textarea',
            label: 'Sample accommodations description',
            name: 'sample_accommodations_description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'max',
                params: [2000, 'Description is too long. Keep it under 2000 characters']
              }
            ],
            grid: { xs: 12 },
            helperText: 'If empty there will be a default description'
          },

          {
            type: 'number',
            name: 'min_participants',
            label: 'Num. min. participants',
            grid: { xs: 6 },
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'min',
                params: [1, 'Minimum number of participants is 1']
              },
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'number',
            name: 'max_participants',
            label: 'Num. max. participants',
            grid: { xs: 6 },
            validationType: 'number',
            required: true,
            validations: [
              {
                type: 'min',
                params: [1, 'Minimum number of participants is 1']
              },
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'select',
            multiple: true,
            name: 'agendas',
            label: 'Agendas',
            validationType: 'array',
            validations: null,
            labelProp: 'selectTitle',
            valueProp: 'id',
            link: '/dashboard/agendas/{value}',
            onNoResults: handleNoResults,
            optionsGetter: { 
              endpoints: [
                {
                  entity: 'agenda',
                  params: {
                    is_template: true,
                    transformResponse: true,
                    ...(venueType === 'town' ? { town: venue.id } : { all_in_venue: venue.id }),
                  }
                }
              ]
            },
            grid: { xs: 6 },
          },
          {
            type: 'select',
            multiple: true,
            name: 'categories',
            label: 'Categories *',
            required: true,
            validationType: 'array',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
              },
              {
                type: 'min',
                params: [1, 'Please select at least one category']
              }
            ],
            labelProp: 'name',
            valueProp: 'id',
            optionsGetter: { 
              endpoints: [
                {
                  entity: 'packageCategories',
                }
              ]
            },
            grid: { xs: 6 },
          },
          {
            type: 'video',
            name: 'video',
            label: 'Upload a video',
            validationType: 'string',
            placeholder: 'Upload a video',
            collectionName: 'package',
            containerStyles: {
              minWidth: '500px'
            },
            innerField: {
              validationType: 'mixed',
              validations: [
                {
                  type: 'fileSize',
                  params: ['File too large', '104860000']
                },
                {
                  type: 'fileFormat',
                  params: ['Unsupported Format', ['video/mp4']]
                },
                {
                  type: 'nullable'
                }
              ],
              maxSize: 104860000,
              accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
            }
          },
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default getSchema