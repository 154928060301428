import { ROOMS_OPTIONS } from './preferences'

export const FIELD_TYPE_OPTIONS = [
  {
    label: 'Multiple choices',
    value: 'SELECT'
  },
  {
    label: 'Free text',
    value: 'TEXT'
  }
]

const CUSTOM_FIELD_TYPE_OPTIONS = [
  ...FIELD_TYPE_OPTIONS,
  {
    label: 'Checkbox',
    value: 'CHECKBOX'
  }
]

export const DEFAULT_VALUES = {
  room_sharing: {
    title: 'Shared rooms?',
    description: 'Will you share your room with a colleague?',
    active: true,
    required: false,
    field_type: 'SELECT'
  },
  roommates: {
    title: 'Roommates',
    description: 'Please select one or more people you wish to share your room with.',
    active: false,
    required: false,
    field_type: 'SELECT'
  },
  transfer: {
    title: 'How will you come?',
    description: '',
    active: true,
    required: false,
    field_type: 'SELECT'
  },
  transfer_details: {
    title: 'Give us more details about your transfer',
    description: 'These will be useful to organize transports and checkins onsite',
    active: false,
    required: false,
    field_type: 'TEXT'
  },
  personal_infos: {
    title: 'Personal infos',
    description: 'Specify your special needs or any other information that could be useful for you during the offsite.',
    active: true,
    required: false,
  }
}

const DEFAULT_FIELDSETS = [
  {
    name: 'room_sharing',
    title: 'Room sharing preference',
    subtitle: 'Set what your users will see when choosing their preference about the Room Sharing option',
    customizable: false,
    getFields: () => [
      {
        type: 'select',
        name: 'room_sharing',
        grid: { xs: 6 },
        options: ROOMS_OPTIONS
      }
    ]
  },
  {
    name: 'roommates',
    customizable: true,
    title: 'Roommates preference',
    subtitle: 'Set what your users will see when choosing they decide to share their room with a colleague',
    getFields: (values = {}, options) => {
      const { roommates_field_type } = values
      return roommates_field_type === 'SELECT'
        ? [
            {
              type: 'select',
              name: 'roommates',
              label: 'Roommates',
              placeholder: 'Choose one or more people',
              labelProp: 'name',
              valueProp: 'id',
              multiple: true,
              helperText: 'Choose one or more people (this is an example)',
              options: options.participants
                ? options.participants.map(({ first_name, last_name, id }) => ({
                    label: `${first_name} ${last_name}`,
                    value: id,
                    disabled: true
                  }))
                : [],
              grid: { xs: 6 }
            }
          ]
        : [
            {
              type: 'text',
              name: 'roommates_string',
              label: 'Roommates',
              placeholder: 'Write one or more people',
              grid: { xs: 6 }
            }
          ]
    }
  },
  {
    name: 'transfer',
    customizable: true,
    title: 'Transfer preference',
    subtitle: 'Set what your users will see when organizing their transfer',
    getFields: (values) => {
      return [
        {
          type: 'text',
          name: 'spacer',
          hidden: true,
          grid: { xs: 6 },
        },
        {
          type:
            values && values.transfer_field_type && values.transfer_field_type === 'SELECT'
              ? 'inputlist'
              : 'text',
          name: 'transfer',
          label: 'Transfer',
          watchable: true,
          placeholder: 'Transfer',
          grid: { xs: 6 },
          validationType: 'string',
          validations: values && values.transfer_field_type && values.transfer_field_type === 'SELECT' ? [
            {
              type: 'required',
              params: ['This field is required']
            }
          ] : null
        },
        {
          type:
            values && values.transfer_field_type && values.transfer_field_type === 'SELECT'
              ? 'inputlist'
              : 'text',
          name: 'transfer_return',
          watchable: true,
          label: 'Transfer (return)',
          placeholder: 'Transfer (return)',
          grid: { xs: 6 },
          validationType: 'string',
          validations: values && values.transfer_field_type && values.transfer_field_type === 'SELECT' ? [
            {
              type: 'required',
              params: ['This field is required']
            }
          ] : null
        }
      ]
    }
  },
  {
    name: 'transfer_details',
    customizable: false,
    title: 'Transfer details preference',
    subtitle: 'Set what your users will see when organizing their transfer',
    getFields: (values) => [
      {
        type: 'datepicker',
        name: 'arrival_date',
        label: 'Arrival date',
        validationType: 'string',
        placeholder: 'Arrival date',
        validations: [
          {
            type: 'nullable'
          }
        ],
        grid: { xs: 6 },
        inputProps: {
          variant: 'outlined'
        },
        timepicker: {
          label: 'Time'
        },
      },
      {
        type: 'datepicker',
        name: 'departure_date',
        label: 'Departure date',
        validationType: 'string',
        validations: [
          {
            type: 'nullable'
          }
        ],
        placeholder: 'Departure date',
        grid: { xs: 6 },
        inputProps: {
          variant: 'outlined'
        },
        timepicker: {
          label: 'Time'
        },
      },
      {
        type: 'select',
        name: `transfer_from_field_type`,
        validationType: 'string',
        validations: null,
        label: 'Transfer to - Answer type',
        options: FIELD_TYPE_OPTIONS,
        grid: { xs: 6 },
        fieldMargin: 0
      },
      {
        type: 'select',
        name: `transfer_to_field_type`,
        validationType: 'string',
        validations: null,
        label: 'Transfer from - Answer type',
        options: FIELD_TYPE_OPTIONS,
        grid: { xs: 6 },
        fieldMargin: 0
      },
      {
        type:
          values && values.transfer_from_field_type && values.transfer_from_field_type === 'SELECT'
            ? 'inputlist'
            : 'text',
        name: 'transfer_from',
        watchable: true,
        label: 'Transfer from',
        placeholder: 'Transfer from',
        grid: { xs: 6 },
        boxStyles: { mb: 0 },
        validationType: 'string',
        validations: values && values.transfer_from_field_type && values.transfer_from_field_type === 'SELECT' ? [
          {
            type: 'required',
            params: ['This field is required']
          }
        ] : null
      },
      {
        type:
          values && values.transfer_to_field_type && values.transfer_to_field_type === 'SELECT'
            ? 'inputlist'
            : 'text',
        name: 'transfer_to',
        watchable: true,
        label: 'Transfer to',
        placeholder: 'Transfer to',
        grid: { xs: 6 },
        boxStyles: { mb: 0 },
        validationType: 'string',
        validations: values && values.transfer_to_field_type && values.transfer_to_field_type === 'SELECT' ? [
          {
            type: 'required',
            params: ['This field is required']
          }
        ] : null
      },
      {
        type: 'text',
        name: 'arrival_reservation_number',
        label: 'Arrival reservation number',
        placeholder: 'ABC1234',
        grid: { xs: 6 }
      },
      {
        type: 'text',
        name: 'departure_reservation_number',
        label: 'Departure reservation number',
        placeholder: 'ABC1234',
        grid: { xs: 6 }
      }
    ]
  },
  {
    name: 'personal_infos',
    customizable: false,
    title: 'Personal infos preference',
    subtitle: 'We recommand to always show these questions as they are fundamental for the smooth running of the event',
    getFields: () => [
      {
        type: 'textarea',
        hideToolbar: true,
        name: 'special_needs',
        label: 'Special needs',
        placeholder: 'Personal infos',
        grid: { xs: 8, mr: 12 }
      },
      {
        type: 'select',
        name: 'dietary_options',
        label: 'Dietary options',
        placeholder: 'Dietary options',
        labelProp: 'name',
        valueProp: 'id',
        multiple: true,
        optionsGetter: {
          endpoints: [
            {
              entity: 'dietaryOptions',
            }
          ]
        },
        grid: { xs: 4 }
      },
      {
        type: 'text',
        name: 'dietary_options_other',
        label: 'Other',
        placeholder: 'Other',
        grid: { xs: 4, mr: 8 }
      },
      {
        type: 'select',
        name: 'allergies',
        label: 'Allergies',
        placeholder: 'Allergies',
        labelProp: 'name',
        valueProp: 'id',
        multiple: true,
        optionsGetter: {
          endpoints: [
            {
              entity: 'foodAllergies',
            }
          ]
        },
        grid: { xs: 4 }
      },
      {
        type: 'text',
        name: 'allergies_other',
        label: 'Other',
        placeholder: 'Other',
        grid: { xs: 4, mr: 8 }
      }
    ]
  }
]

export const getFieldsetsInitialValues = ({ settings, options }) => {
  return DEFAULT_FIELDSETS.reduce((acc, fieldset) => {
    const existingFieldset =
      settings &&
      settings.fieldsets &&
      settings.fieldsets.find((sett) => sett.name === fieldset.name)
    const _default = DEFAULT_VALUES[fieldset.name]
    acc[`${fieldset.name}_title`] = existingFieldset ? existingFieldset.title : _default.title
    acc[`${fieldset.name}_description`] = existingFieldset
      ? existingFieldset.description
      : _default.description
    acc[`${fieldset.name}_active`] = existingFieldset ? existingFieldset.active : _default.active
    acc[`${fieldset.name}_required`] = existingFieldset
      ? existingFieldset.active ? existingFieldset.required
      : false : _default.required
    const fieldType = existingFieldset && existingFieldset.field_type
    ? existingFieldset.field_type.toUpperCase()
    : _default.field_type
    acc[`${fieldset.name}_field_type`] = fieldType

    let additionalValues = {}
    if (fieldset.name === 'transfer_details') {
      const transfer_from_field_type = existingFieldset?.fields.find((f) => f.name === 'transfer_from')?.type.toUpperCase() || 'TEXT'
      const transfer_to_field_type = existingFieldset?.fields.find((f) => f.name === 'transfer_to')?.type.toUpperCase() || 'TEXT'
      additionalValues = {
        transfer_from_field_type,
        transfer_to_field_type
      }
      acc.transfer_from_field_type = transfer_from_field_type
      acc.transfer_to_field_type = transfer_to_field_type

    }

    fieldset.getFields({ [`${fieldset.name}_field_type`]: fieldType, ...additionalValues }, options)
      .forEach((field) => {
        const fieldName = ['transfer', 'transfer_return', 'transfer_to', 'transfer_from'].includes(field.name) ? field.name : `${field.name}_options`
        const existingField = existingFieldset && existingFieldset.fields.find((f) => f.name === field.name)

        const getOptions = () => {
          if (!existingField || !existingField.options) return null
          if (field.type === 'inputlist') {
            if (Array.isArray(existingField.options)) {
              return existingField.options.map(({value}) => value).join('|')
            } else if (typeof existingField.options === 'string') {
              return existingField.options
            }
          }
          return existingField.options
        }

        acc[fieldName] = getOptions()
      })
      return acc
  }, {})
}

export const getCustomQuestionInitialValues = (customQuestions) => {
  return customQuestions.reduce((acc, customQuestion) => {
    const index = Number(customQuestion.field_key.split('custom_question_')[1])
    acc[customQuestion.field_key] = {
      [`${customQuestion.field_key}_title`]: customQuestion?.title || `Custom question ${index + 1}`,
      [`${customQuestion.field_key}_description`]: customQuestion?.description || `Custom question description ${index + 1}`,
      [`${customQuestion.field_key}_field_type`]: customQuestion?.type.toUpperCase() || 'TEXT',
      [`${customQuestion.field_key}_options`]: customQuestion?.options && customQuestion?.options.length ? customQuestion.options.map((o) => o.value).join('|') : null,
      [`${customQuestion.field_key}_required`]: !!customQuestion?.required,
    }
    return acc
  }, {})
}

const getOptions = (options, value, type) => {
  if (options) return options.map(({ disabled, ...o }) => ({ ...o }))
  if (value && type === 'inputlist') {
    return value
      .split('|')
      .filter((opt) => opt !== '')
      .map((opt) => ({ label: opt, value: opt }))
  }
  return value
}

export const formatValuesToServer = ({ values, customValues, options }) => {
  // console.log(values, customValues)
  const form = {
    fieldsets: DEFAULT_FIELDSETS.map((fieldset) => {
      const { name, customizable, getFields } = fieldset
      return {
        name,
        title: values[`${name}_title`],
        description: values[`${name}_description`],
        active: values[`${name}_active`],
        required: values[`${name}_required`],
        ...( customizable ? { field_type: values[`${name}_field_type`] } : {}),
        fields: getFields(values, options)
          .filter(f => !f.name.includes('field_type') && !f.hidden) // only for hidden fields or transfer_to / transfer_from
          .map((field) => {
          const { name, options: fieldOptions, type, label } = field
          const _options = getOptions(fieldOptions, values[name], type)
          return {
            name: name.includes('dietary_options') ? name : name.replace('_options', ''),
            type: type === 'inputlist' ? 'select' : type,
            label,
            ...( type === 'select' ? { multiple: field.multiple } : {}),
            ...( _options ? { options: _options } : {})
          }
        })
      }
    }),
    custom_fields: Object.keys(customValues).map((key) => {
      return {
        field_key: key,
        type: customValues[key][`${key}_field_type`].toLowerCase(),
        title: customValues[key][`${key}_title`],
        description: customValues[key][`${key}_description`],
        required: customValues[key][`${key}_required`],
        label: customValues[key][`${key}_label`],
        ...(customValues[key][`${key}_options`] ? { options: customValues[key][`${key}_options`].split('|').map((o) => ({ label: o, value: o })) } : {})
      }
    })
  }
  return form
}

const getDefaultFields = (name) => {
  return [
    {
      type: 'switch',
      name: `${name}_active`,
      validationType: 'boolean',
      validations: null,
      label: 'Show question',
      grid: { xs: 9 }
    },
    {
      type: 'checkbox',
      name: `${name}_required`,
      validationType: 'boolean',
      validations: null,
      label: 'Required question',
      grid: { xs: 3 },
      styles: { display: 'flex', justifyContent: 'flex-end' }
    },
    {
      type: 'editable',
      name: `${name}_title`,
      validationType: 'string',
      validations: null,
      variant: 'h5',
      canReset: false,
      iconsColor: 'secondary',
      grid: { xs: 12, pt: '0 !important' },
      typographyProps: { mb: '1 !important' }
    },
    {
      type: 'editable',
      name: `${name}_description`,
      validationType: 'string',
      validations: null,
      variant: 'body2',
      canReset: false,
      iconsColor: 'secondary',
      grid: { xs: 12, pt: '0 !important' }
    }
  ]
}

const getExtraFields = ({ name, customizable, getFields, values, options = {}, custom }) => {
  return [
    ...(customizable
      ? [
          {
            type: 'select',
            name: `${name}_field_type`,
            validationType: 'string',
            validations: null,
            label: 'Answer type',
            options: custom ? CUSTOM_FIELD_TYPE_OPTIONS : FIELD_TYPE_OPTIONS,
            grid: { xs: 6 }
          }
        ]
      : []),
    ...getFields(values, options)
  ]
}

const getSchema = ({ values, options = {} }) => {
  let watchedFields = DEFAULT_FIELDSETS.reduce((acc, fieldset) => {
    const { name } = fieldset
    return [...acc, `${name}_field_type`, `${name}_title`, `${name}_description`, `${name}_required`, `${name}_active`]
  }, [])
  const fieldsets = DEFAULT_FIELDSETS.map((fieldset) => {
    const { name, title, subtitle, getFields, customizable } = fieldset
    const fields = [
      ...getDefaultFields(name),
      ...getExtraFields({ name, customizable, getFields, values, options })
    ]
    const watchables = fields.filter(f => f.watchable || f.name.includes('field_type'))
    if (watchables && watchables.length && watchables.some(w => !watchedFields.includes(w.name))) {
      watchedFields = Array.from(new Set([...watchedFields, ...watchables.map(w => w.name)]))
    }
    return {
      id: name,
      name,
      type: 'accordion',
      title,
      subtitle,
      sx: { backgroundColor: 'background.paper !important' },
      fields
    }
  })
  return {
    id: 'preferences-form',
    name: 'preferences-form',
    watchedFields,
    fieldsets
  }
}

export const getCustomQuestionSchema = ({ index, values }) => {
  const name = `custom_question_${index}`
  const defaultFields = getDefaultFields(name).filter((f) => !f.name.includes('active'))
  const extraFields = getExtraFields({ custom: true, name, customizable: true, getFields: () => {
    const fieldType = values && values[`${name}_field_type`] ? values[`${name}_field_type`].toLowerCase() : 'text'
    return  [{
      type:
        fieldType === 'select'
          ? 'inputlist'
          : fieldType,
      name: fieldType === 'select' ? `${name}_options` : `__${name}__`,
      label: ['text', 'checkbox'].includes(fieldType) ? values[`${name}_label`] || 'User answer' : 'Add options for user answer',
      placeholder: 'Option',
      grid: { xs: 6 },
      ...( fieldType === 'select' ? {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['This field is required']
          }
        ]

      } : {})
    }, ...(fieldType === 'checkbox' ? [{
      type: 'input',
      name: `${name}_label`,
      placeholder: 'Checkbox label',
      grid: { xs: 6 }
    }] : [])]
  } })
  return {
    id: name,
    name,
    watchedFields: [`${name}_field_type`, `${name}_title`, `${name}_description`, `${name}_label`, `${name}_required`, `${name}_options`],
    fieldsets: [
      {
        id: name,
        name,
        sx: {
          marginInline: '-12px',
          marginBottom: '24px',
          paddingInline: '10px',
          paddingTop: '24px',
          paddingBottom: '16px',
          backgroundColor: 'grey.200',
          borderRadius: '8px'
        },
        fields: [
          ...defaultFields,
          ...extraFields
        ]
      }
    ]
  }
}

export default getSchema
