const experience = ({ groups, experience, dispatch, onPublishChange }) => {
  const distanceVisible = !!experience.position_switch

  const publishButton = experience.id
    ? experience.published_at
      ? {
          label: 'Unpublish',
          color: 'warning',
          tooltip: 'By unpublishing, you will not be able to see the public page anymore',
          icon: 'visibility_off',
          onClick: () => onPublishChange(false, experience)
        }
      : {
          label: 'Publish',
          color: 'success',
          tooltip: 'Be sure to have saved before publishing',
          icon: 'rocket_launch',
          onClick: () => onPublishChange(true, experience)
        }
    : null

  const hosts = groups.find((g) => g.name === 'Host')
  const showIsInsideVenue = experience && experience.all_in_venue

  return {
    id: 'experiences-form',
    title: 'Experience Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: 'General',
        type: 'accordion',
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            label: 'Slug',
            name: 'slug',
            slugify: true,
            required: !!experience.id,
            hidden: !experience.id,
            validationType: 'string',
            validations: experience.id
              ? [
                  {
                    type: 'slug',
                    params: ['experiences', 'This slug is not available', experience.slug, dispatch]
                  },
                  {
                    type: 'required',
                    params: ['This field is required']
                  }
                ]
              : null
          },
          {
            type: 'select',
            name: 'town',
            label: 'Town / Venue',
            labelProp: 'name',
            valueProp: 'id',
            groupBy: (o) => o.entity,
            optionsGetter: {
              endpoints: [
                { name: 'Towns', entity: 'town', prefix: 'town' },
                { name: 'Venues', entity: 'allInVenue', prefix: 'allInVenue' }
              ]
            },
            grid: { xs: 6 },
            validationType: 'string',
            validations: [
              {
                type: 'nullable',
                params: []
              }
            ]
          },
          {
            type: 'select',
            name: 'host',
            label: 'Host',
            labelProp: 'name',
            valueProp: 'profileId',
            required: true,
            optionsGetter: {
              endpoints: [
                {
                  name: 'host',
                  entity: 'profilesByRole',
                  params: { roles: hosts.id, transformResponse: true }
                }
              ]
            },
            grid: { xs: 6 },
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              }
            ]
          },
          {
            type: 'address',
            name: 'address',
            label: 'Address',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            label: 'Phone Number',
            name: 'phone_number',
            required: true,
            validationType: 'text',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'switch',
            name: 'is_inside_venue',
            label: 'Experience is inside the venue?',
            grid: { xs: 6 },
            validationType: 'boolean',
            validations: null,
            hidden: !showIsInsideVenue
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: null
          },
          {
            type: 'number',
            label: 'Min price',
            name: 'price_min',
            required: true,
            validationType: 'string',
            suffix: '€',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Max price',
            name: 'price_max',
            required: true,
            suffix: '€',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Normal price',
            name: 'normal_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Weekday price',
            name: 'weekday_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          // {
          //   type: 'switch',
          //   name: 'available',
          //   label: 'Is the experience available?',
          //   validationType: 'boolean',
          //   validations: null
          // },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            required: true,
            placeholder: 'Write a description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'max',
                params: [2000, 'Description is too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'video',
            label: 'Video',
            name: 'video',
            placeholder: 'Upload a video',
            validationType: 'string',
            collectionName: 'experiences',
            innerField: {
              validationType: 'mixed',
              validations: [
                {
                  type: 'fileSize',
                  params: ['File too large', '104860000']
                },
                {
                  type: 'fileFormat',
                  params: ['Unsupported Format', ['video/mp4']]
                },
                {
                  type: 'nullable'
                }
              ],
              maxSize: 104860000,
              accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
            }
          }
        ]
      },
      {
        id: 'settings',
        title: 'Experience Settings',
        type: 'accordion',
        fields: [
          {
            type: 'select',
            name: 'rank',
            label: 'Category of experience',
            required: true,
            optionsGetter: {
              endpoints: [
                {
                  name: 'ranks',
                  entity: 'locationRanks'
                }
              ]
            },
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'select',
            name: 'categories',
            label: 'Tipology of experience',
            multiple: true,
            optionsGetter: {
              endpoints: [{ name: 'categories', entity: 'locationCategories', id: 'experience' }]
            },
            labelProp: 'name',
            valueProp: 'id',
            required: true,
            validationType: 'array',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              },
              {
                type: 'min',
                params: [1, 'You must select at least one category']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Full capacity',
            name: 'capacity',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'duration',
            label: 'Duration',
            name: 'duration',
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6, mt: -4 }
          },
          {
            type: 'number',
            label: 'Min number of participants',
            name: 'min_participants',
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Max number of participants',
            name: 'max_participants',
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'text',
            label: 'Meeting point name',
            name: 'meeting_point',
            validationType: 'string',
            validations: null,
            grid: { xs: 6, mt: 4 }
          },
          {
            type: 'address',
            name: 'meeting_point_address',
            label: 'Meeting point',
            latitudeProp: 'meeting_point_latitude',
            longitudeProp: 'meeting_point_longitude',
            validationType: 'string'
          },
          {
            type: 'switch',
            name: 'position_switch',
            label: 'Is the experience outside the center?',
            validationType: 'boolean',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            name: 'position',
            label: 'Distance from center (kilometers)',
            placeholder: 'Position',
            suffix: 'km',
            hidden: !distanceVisible,
            validationType: 'string',
            validations: null,
            grid: { xs: 4 }
          },
          {
            type: 'textarea',
            name: 'transfer',
            label: 'Transfer informations',
            placeholder: '',
            validationType: 'string',
            validations: null
          },
          {
            type: 'textarea',
            name: 'included',
            label: 'Things to know',
            placeholder: '',
            validationType: 'string',
            validations: null
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default experience
