import { useState, useEffect, forwardRef } from 'react'
// prop-type is a library for typechecking of props
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import MDInput from 'components/MDInput'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { slugify } from 'utils/functions'

const Input = forwardRef(
  (
    {
      type,
      name,
      value,
      label,
      errors,
      prefix,
      suffix,
      icon,
      helperText,
      success,
      hidden,
      boxStyles = {},
      sx = {},
      setFieldTouched,
      setFieldValue,
      onChange,
      onEnter,
      onFocus,
      ...rest
    },
    ref
  ) => {
    const [defaultValue, setDefaultValue] = useState(value)
    const [touched, setTouched] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const additionalProps = {}

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => event.preventDefault()

    const handleOnKeydown = (e) => {
      if (e.key === 'Enter' && onEnter) {
        e.preventDefault()
        e.stopPropagation()
        onEnter(e)
      }
    }

    const handleOnFocus = () => {
      setTouched(true)
      if (onFocus && typeof onFocus === 'function') {
        onFocus()
      }
    }

    const handleOnChange = (e) => {
      if (onChange && typeof onChange === 'function') {
        const { name, value, valueAsNumber } = e.target
        let _value = type === 'number' ? valueAsNumber : value
        if (rest.slugify) {
          _value = slugify(_value)
        }
        const _event = {
          target: {
            name,
            value: _value
          }
        }
        onChange(_event)
      }
    }

    if (type === 'password') {
      additionalProps.endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      )
    }

    if (suffix) {
      additionalProps.endAdornment = (
        <InputAdornment position="end">
          <MDTypography fontSize={14}>{suffix}</MDTypography>
        </InputAdornment>
      )
    }
    if (prefix) {
      additionalProps.startAdornment = (
        <InputAdornment position="start" sx={{ '& ~ input': { paddingLeft: '0px' } }}>
          <MDTypography fontSize={14}>{prefix}</MDTypography>
        </InputAdornment>
      )
    }

    useEffect(() => {
      if (value && !touched && !defaultValue) {
        setDefaultValue(value)
      }
    }, [value, touched])

    return (
      <MDBox
        key={defaultValue}
        mb={hidden ? 0 : 4}
        position="relative"
        {...boxStyles}
        sx={{
          opacity: hidden ? 0 : 1,
          visibility: hidden ? 'hidden' : 'visible',
          height: hidden ? 0 : 'auto',
          transition: 'opacity 0.2s'
        }}>
        <MDInput
          {...rest}
          value={value}
          ref={ref}
          name={name}
          inputProps={rest.inputProps}
          InputProps={{ ...rest.InputProps, ...additionalProps }}
          type={showPassword ? 'text' : type}
          sx={{ ...sx, label: { minHeight: '14px' } }}
          id={name}
          variant={rest.variant || 'outlined'}
          label={label}
          fullWidth
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onKeyDown={handleOnKeydown}
          error={!!errors}
        />
        {errors ? (
          <MDBox position="absolute" sx={{ lineHeight: 1 }}>
            <MDTypography color="error" variant="caption">
              {errors}
            </MDTypography>
          </MDBox>
        ) : null}
        {helperText && !success && !errors ? (
          <MDBox position="absolute" sx={{ top: 'calc(100% + 4px)', lineHeight: 0.8 }}>
            <MDTypography variant="caption">{helperText}</MDTypography>
          </MDBox>
        ) : null}
      </MDBox>
    )
  }
)

// typechecking props for FormField
Input.propTypes = {
  label: PropTypes.string
}

export default Input
