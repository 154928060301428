const town = ({ groups, town, dispatch, onPublishChange }) => {
  const angels = groups && groups.length ? groups.find((g) => g.name === 'Town Angel') : {}
  const publishButton = town.id
    ? town.published_at
      ? {
          label: 'Unpublish',
          color: 'warning',
          icon: 'visibility_off',
          tooltip: 'By unpublishing, you will not be able to see the public page anymore',
          onClick: () => onPublishChange(false, town)
        }
      : {
          label: 'Publish',
          color: 'success',
          tooltip: 'Be sure to have saved before publishing',
          icon: 'rocket_launch',
          onClick: () => onPublishChange(true, town)
        }
    : null
  return {
    id: 'town-form',
    title: 'Town Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        title: '',
        fields: [
          {
            type: 'text',
            label: 'Name of the town',
            name: 'name',
            required: true,
            validationType: 'string',
            helperText:
              'The name of the town should be unique. Try to not write the name of a town that already exists.',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            label: 'Slug',
            name: 'slug',
            slugify: true,
            required: !!town.id,
            hidden: !town.id,
            validationType: 'string',
            validations: town.id
              ? [
                  {
                    type: 'slug',
                    params: ['towns', 'This slug is not available', town.slug, dispatch]
                  },
                  {
                    type: 'required',
                    params: ['This field is required']
                  }
                ]
              : null
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            required: true,
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'select',
            name: 'town_angel',
            label: 'Angel',
            labelProp: 'name',
            valueProp: 'profileId',
            optionsGetter: {
              endpoints: [
                {
                  name: 'angel',
                  entity: 'profilesByRole',
                  params: { roles: angels.id, transformResponse: true }
                }
              ]
            },
            grid: { xs: 6 },
            validationType: 'number',
            validations: [
              {
                type: 'nullable'
              }
            ]
          },
          {
            type: 'address',
            name: 'address',
            label: 'Location',
            helperText: 'This information is useful for Google Maps',
            validationType: 'string',
            addressComponents: [
              {
                name: 'country',
                component: 'country'
              },
              {
                name: 'region',
                component: 'region'
              }
            ]
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            required: true,
            placeholder: 'Write a description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'max',
                params: [2000, 'Description is too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'textarea',
            name: 'transports_description',
            label: 'Transports Description',
            placeholder: 'Write a description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'max',
                params: [2000, 'Transports description is too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'inputlist',
            name: 'transports',
            label: 'List of transports and travel time',
            placeholder: '2h30 from Rome by train',
            validationType: 'string',
            validations: null
          },
          {
            type: 'video',
            label: 'Video',
            name: 'video',
            placeholder: 'Upload a video',
            validationType: 'string',
            collectionName: 'towns',
            innerField: {
              validationType: 'mixed',
              validations: [
                {
                  type: 'fileSize',
                  params: ['File too large', '104860000']
                },
                {
                  type: 'fileFormat',
                  params: ['Unsupported Format', ['video/mp4']]
                },
                {
                  type: 'nullable'
                }
              ],
              maxSize: 104860000,
              accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
            }
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default town
