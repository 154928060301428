const workshop = ({ groups, workshop, dispatch, onPublishChange }) => {
  const distanceVisible = !!workshop.position_switch

  const publishButton = workshop.id
    ? workshop.published_at
      ? {
          label: 'Unpublish',
          color: 'warning',
          tooltip: 'By unpublishing, you will not be able to see the public page anymore',
          icon: 'visibility_off',
          onClick: () => onPublishChange(false, workshop)
        }
      : {
          label: 'Publish',
          color: 'success',
          tooltip: 'Be sure to have saved before publishing',
          icon: 'rocket_launch',
          onClick: () => onPublishChange(true, workshop)
        }
    : null

  const hosts = groups.find((g) => g.name === 'Host')
  const showIsInsideVenue = workshop && workshop.all_in_venue

  return {
    id: 'workshops-form',
    title: 'Workshop Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: 'General',
        type: 'accordion',
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            label: 'Slug',
            name: 'slug',
            slugify: true,
            required: !!workshop.id,
            hidden: !workshop.id,
            validationType: 'string',
            validations: workshop.id
              ? [
                  {
                    type: 'slug',
                    params: ['workshops', 'This slug is not available', workshop.slug, dispatch]
                  },
                  {
                    type: 'required',
                    params: ['This field is required']
                  }
                ]
              : null
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: null
          },
          {
            type: 'number',
            label: 'Min price',
            name: 'price_min',
            required: true,
            validationType: 'string',
            suffix: '€',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 4 }
          },
          {
            type: 'number',
            label: 'Max price',
            name: 'price_max',
            required: true,
            suffix: '€',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 4 }
          },
          {
            type: 'number',
            label: 'Normal price',
            name: 'normal_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 4 }
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            required: true,
            maxLength: 2000,
            placeholder: 'Write a description',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'max',
                params: [2000, 'Description is too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'video',
            label: 'Video',
            name: 'video',
            placeholder: 'Upload a video',
            validationType: 'string',
            collectionName: 'experiences',
            innerField: {
              validationType: 'mixed',
              validations: [
                {
                  type: 'fileSize',
                  params: ['File too large', '104860000']
                },
                {
                  type: 'fileFormat',
                  params: ['Unsupported Format', ['video/mp4']]
                },
                {
                  type: 'nullable'
                }
              ],
              maxSize: 104860000,
              accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
            }
          }
        ]
      },
      {
        id: 'settings',
        title: 'Workshop Settings',
        type: 'accordion',
        fields: [
          {
            type: 'select',
            name: 'rank',
            label: 'Category of workshop',
            required: true,
            optionsGetter: {
              endpoints: [
                {
                  name: 'ranks',
                  entity: 'locationRanks'
                }
              ]
            },
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'select',
            name: 'categories',
            label: 'Tipology of workshop *',
            multiple: true,
            optionsGetter: {
              endpoints: [{ name: 'categories', entity: 'locationCategories', id: 'workshop' }]
            },
            labelProp: 'name',
            valueProp: 'id',
            required: true,
            validationType: 'array',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'min',
                params: [1, 'You must select at least one tipology']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Min number of participants',
            name: 'min_participants',
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Max number of participants',
            name: 'max_participants',
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'duration',
            label: 'Duration',
            name: 'duration',
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'textarea',
            name: 'included',
            label: 'Things to know',
            placeholder: '',
            validationType: 'string',
            validations: null
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default workshop
