import MDBox from 'components/MDBox'
import Icon from '@mui/material/Icon'

import { VideoSkeleton } from './Skeletons'
import { constants } from 'hooks/useBunnyCollection'

const VideoComponent = ({
  video,
  editable,
  deletable,
  selectable,
  selected,
  onEdit,
  onSelect,
  onDelete
}) => {
  const status = {
    'CREATING': 'Your video still needs to be created. <br /> <span style="font-weight:bold;">Please don\'t refresh the page!</span>',
    0: 'The video has been created but not upload yet',
    1: 'The video has been uploaded but is still processing in the background. It could take a few more minutes, but you can leave the page in the meanwhile',
    2: 'The video is still processing. It could take a few more minutes but you can leave the page in the meanwhile.',
    3: 'Just a few more seconds and your video should be ready. You can leave the page in the meanwhile.',
    4: 'Finished',
    5: 'The video is giving an error',
    6: 'There was an error during the upload of the video. Please try again'
  }

  const iconStyles = {
    transitionProperty: 'opacity, transform',
    transitionDuration: '0.3s',
    opacity: 0,
    transform: 'scale(0.8)',
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: '10',
    color: 'white !important',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    backgroundColor: 'primary.main',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const handleOnSelect = () => {
    onSelect(video)
  }

  switch (video?.status) {
    case 4:
      return (
        <MDBox
          position="relative"
          pt="56.25%"
          className={selected ? 'selected' : ''}
          sx={{
            '&:hover, &.selected': {
              '.video-component__icon': {
                opacity: 1,
                transform: 'scale(1)'
              }
            }
          }}>
          {editable ? (
            <MDBox display="flex">
              <MDBox
                className="video-component__icon"
                sx={{...iconStyles, right: '42px'}}
                onClick={onEdit}>
                <Icon fontSize="small">edit</Icon>
              </MDBox>
              <MDBox
                className="video-component__icon"
                sx={iconStyles}
                onClick={onDelete}>
                <Icon fontSize="small">delete</Icon>
              </MDBox>
            </MDBox>
          ) : null}
          {selectable ? (
            <MDBox
              className="video-component__icon"
              sx={iconStyles}
              onClick={handleOnSelect}>
              <Icon fontSize="small">check</Icon>
            </MDBox>
          ) : null}
          <iframe
            title="Video"
            src={`https://iframe.mediadelivery.net/embed/${constants.BUNNY_COLLECTION_LIBRARY_ID}/${video.guid}?autoplay=false&loop=false&muted=false&preload=true`}
            loading="lazy"
            style={{
              border: 0,
              position: 'absolute',
              top: 0,
              height: '100%',
              width: '100%',
              opacity: editable || selected ? 1 : 0.6
            }}
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
            allowfullscreen="true"></iframe>
        </MDBox>
      )
    default:
      return <VideoSkeleton status={status[video?.status]} />
  }
}

export default VideoComponent
